import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(
        private readonly snackBar: MatSnackBar,
        public dialog: MatDialog
    ) {}

    /**
     * Presents a toast displaying the message with a green background
     * @param message Message to display
     * @example
     * this.notificationService.success("confirm oked");
     */
    success(message: string, action: string = '', duration: number = 2000) {
        this.openSnackBar(message, action, 'snackbar-success', duration);
    }

    /**
     * Presents a toast displaying the message with a green background
     * @param message Message to display
     * @example
     * this.notificationService.warning("confirm oked");
     */
    warning(message: string, action: string = 'Ok', duration: number = 3000) {
        this.openSnackBar(message, action, 'snackbar-warning', duration);
    }

    /**
     * Presents a toast displaying the message with a red background
     * @param message Message to display
     * @example
     * this.notificationService.error("confirm canceled");
     */
    error(message: string, action: string = '', duration: number = 2000) {
        this.openSnackBar(message, action, 'snackbar-error', duration);
    }

    /**
     * Displays a toast with provided message
     * @param message Message to display
     * @param action Action text, e.g. Close, Done, etc
     * @param className Optional extra css class to apply
     * @param duration Optional number of SECONDS to display the notification for
     */
    openSnackBar(
        message: string,
        action: string,
        className = '',
        duration = 2000
    ) {
        this.snackBar.open(message, action, {
            ...(!action ? { duration: duration } : {}),
            verticalPosition: 'top',
            panelClass: className
        });
    }
}
