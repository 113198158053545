import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalOneSettings } from '../../../common/models/global-one-settings';

import { Settings } from '../../../common/models/settings';
import { PaymentSettings } from '../../models/payment-settings';
import { SettingsRepository } from '../../repositories/settings.repository';
import { DatesService } from '../dates/dates.service';
import { VippsSalesUnitsInfo } from '../../models/vipps/vipps-sales-units-info';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    settings = new BehaviorSubject<Settings>(null);

    constructor(
        public http: HttpClient,
        public datesService: DatesService,
        public settingsRepository: SettingsRepository
    ) {
    }

    getSettings(): Observable<Settings> {
        return this.settings.asObservable();
    }

    setSettings(setting: Settings) {
        this.settings.next(setting);
    }

    loadGeneralSettings(): Observable<any> {
        return this.settingsRepository.loadGeneralSettings().pipe(
            tap((settings: Settings) => {
                this.datesService.clockFormat = settings.clockFormat;
                this.setSettings(settings);
            })
        );
    }

    getG1Settings(): Observable<GlobalOneSettings> {
        return this.settingsRepository.getG1Settings();
    }

    getEmailSettings(): Observable<any> {
        return this.settingsRepository.getEmailSettings();
    }

    getOnlineBookingSettings(): Observable<any> {
        return this.settingsRepository.getOnlineBookingSettings();
    }

    getPaymentSettings(): Observable<PaymentSettings[]> {
        return this.settingsRepository.getPaymentSettings();
    }

    setGeneralSettings(formData: any): Observable<any> {
        return this.settingsRepository.setGeneralSettings(formData);
    }

    setEmailSettings(formData: any): Observable<any> {
        return this.settingsRepository.setEmailSettings(formData);
    }

    setOnlineBookingSettings(formData: any): Observable<any> {
        return this.settingsRepository.setOnlineBookingSettings(formData);
    }

    setPaymentSettings(formData: any): Observable<any> {
        return this.settingsRepository.setPaymentSettings(formData);
    }

    startG1Integration(login: string, password: string, shopKey: string) {
        return this.settingsRepository.startG1Integration(
            login,
            password,
            shopKey
        );
    }

    stopG1Integration(login: string, password: string, shopKey: string) {
        return this.settingsRepository.stopG1Integration(
            login,
            password,
            shopKey
        );
    }

    getVippsMerchantByMSN(merchantSerialNumber: string): Observable<VippsSalesUnitsInfo> {
        // return of({
        //     msn: 123456,
        //     name: 'ACME Fantastic Fitness',
        //     businessIdentifier: {
        //         scheme: 'business:NO:ORG',
        //         id: '9876543221'
        //     },
        //     configuration: {
        //         paymentAllowed: false,
        //         captureType: 'ReserveCapture',
        //         skipLandingPageAllowed: false,
        //         recurringAllowed: false
        //     }
        // });
        return this.settingsRepository.getVippsMerchantByMSN(
            merchantSerialNumber
        );
    }
}
