import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    Optional
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription, timer, Subject } from 'rxjs';
import { Link } from '../../layout/link';
import { AccessPages, Actions } from '../models/staff/staffGroupPermissions';
import { PermissionService } from '../services/permission.service';
import { Settings } from '../models/settings';
import { ErrorHandlerService } from '../services/error-handler.service';

@Component({
    template: ''
})
export class BaseComponent implements OnDestroy {
    protected loading = false;
    private subscriptions: Subscription[] = [];
    Actions = Actions;
    Pages = AccessPages;
    protected destroy$ = new Subject<void>();
    settings: Settings;

    constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected errorHandlerService: ErrorHandlerService,
        @Optional() public activatedRoute?: ActivatedRoute,
        protected permissionService?: PermissionService,
    ) {}

    detectChanges() {
        this.changeDetectorRef.detectChanges();
    }

    addSubscription(subscription: Subscription) {
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        this.changeDetectorRef.detach();
        this.subscriptions.forEach(this.cancelSubscription);
        this.destroy$.next();
        this.destroy$.complete();
    }

    // public valuesOf(object: any) {
    //     return ObjectUtils.valuesOf(object);
    // }

    public keysOf(object: any) {
        return Object.keys(object);
    }

    onQueryParamsChanged(): Observable<Params> {
        return this.activatedRoute.queryParams;
    }

    onParamsChanged(): Observable<Params> {
        return this.activatedRoute.params;
    }

    openAbsoluteLink(urlToOpen: string, target: '_blank' | '_self' = '_blank') {
        if (!urlToOpen || urlToOpen.trim().length === 0) {
            return;
        }
        let url = '';
        if (!/^http[s]?:\/\//.test(urlToOpen)) {
            url += 'http://';
        }

        url += urlToOpen;
        window.open(url, target);
    }

    scrollIntoView(el: Element): void {
        if (el) {
            el.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });
        }
    }

    scrollTo(el: Element): void {
        if (el) {
            const isAdmin = !!document.querySelector('webshop-admin-gateway');
            if (isAdmin) {
                el.scrollIntoView({ behavior: 'smooth' });
            } else {
                const window = document.querySelector(
                    'mat-sidenav-content scrollable-view'
                );
                const toolbar = document.querySelector('mat-toolbar');
                let toolbarOffset = 0;
                if (toolbar) {
                    const toolbarRowIndex = toolbar.children.length > 1 ? 1 : 0;
                    toolbarOffset =
                        toolbar.children.item(toolbarRowIndex).clientHeight;
                }
                const y =
                    el.getBoundingClientRect().top +
                    window.scrollTop -
                    toolbarOffset -
                    40;
                window.scrollTo({ behavior: 'smooth', top: y });
            }
        }
    }

    scrollToError(): void {
        const firstElementWithError = document.querySelector('.ng-invalid');
        this.scrollTo(firstElementWithError);
    }

    markForCheck() {
        this.changeDetectorRef.markForCheck();
    }

    asInt(number: any) {
        if (!number) return 0;
        return parseInt(number);
    }

    float(float: string) {
        return parseFloat(float);
    }

    toInt(control: AbstractControl) {
        if (control.valid) {
            control.setValue(parseInt(control.value));
            control.updateValueAndValidity();
        }
    }

    toTwoDecimals(value: number) {
        if (value) {
            return Number(value.toFixed(2));
        }
        return value;
    }

    scheduleRun(timeInMs: number, fn: () => void) {
        this.addSubscription(timer(timeInMs).subscribe(() => fn()));
    }

    runLater(fn: () => void) {
        this.addSubscription(timer(1000).subscribe(() => fn()));
    }

    openDateTimePicker(picker, $event, value) {
        picker._selected = value;
        picker.open();
        $event.stopPropagation();
    }

    static isElementInViewport(el: Element) {
        const rect = el.getBoundingClientRect();

        const offset = 50; // To make sure that element is not at the very bottom;
        return (
            rect.top >= 0 &&
            rect.top + offset <=
                (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    protected cancelSubscription(subscription: Subscription) {
        if (subscription && !subscription.closed) {
            subscription.unsubscribe();
        }
    }

    public getErrorMsg(err?): string {
        return err && err.error?.msg
            ? err.error.msg
            : 'Error occurred please try again';
    }

    updateFormValidity(formGroup: UntypedFormGroup) {
        for (const key in formGroup.controls) {
            if (formGroup.controls.hasOwnProperty(key)) {
                formGroup.controls[key].markAsDirty();
                formGroup.controls[key].markAsTouched();
                formGroup.controls[key].updateValueAndValidity();
            }
        }
    }

    public hasPermission(page: AccessPages, action: Actions): boolean {
        return this.permissionService.isAccessAllowed(page, action);
    }

    canRoute(link: Link) {
        return this.hasPermission(
                this.Pages[link.model.toUpperCase()],
                this.Actions[(link.permission || Actions.VIEW).toUpperCase()]
            );
    }

    canSubmit(page: AccessPages, itemId: number | string) {
        if (itemId) {
            return this.hasPermission(page, this.Actions.UPDATE);
        }
        return this.hasPermission(page, this.Actions.CREATE);
    }

    handleError(error: HttpErrorResponse) {
        this.errorHandlerService.handleError(error);
        this.loading = false;
        this.changeDetectorRef.detectChanges();
    }

}
