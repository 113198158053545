import {ApplicationRef, Injectable, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {LocalStorageRepository} from "./repositories/local-storage.repository";
import {Observable, ReplaySubject} from "rxjs";
import { SettingLocalStorageRepository } from "../user-settings/repository/setting-local-storage-repository";
import { SettingsService } from "./services/settings/settings.service";
import { Settings } from "./models/settings";
import { NotificationService } from "./services/notification.service";

const DEFAULT_DATE_FORMAT = "dd/MM/yyyy";
const DEFAULT_TIME_FORMAT = 'HH:mm';
const DEFAULT_CURRENCY_FORMAT = '1.2-2';

@Injectable({
    providedIn: "root",
})
export class CommonService {
    allowedRoles = ["company", "admin"];
    modals: any = {};
    language: string = "en";
    translation: any = {};
    modalChanges$: {
        [modalKey: string]: ReplaySubject<boolean>;
    } = {};
    setting: Settings;

    constructor(
        public router: Router,
        private notificationService: NotificationService,
        public http: HttpClient,
        public appRef: ApplicationRef,
        private settingsService: SettingsService
    ) {
        this.setLanguage();
        this.getSettings();
        this.getTranslation().then();
    }

    /****************************************************************
     * GET/POST DATA
     ****************************************************************/


    getSettings() {
        this.settingsService.getSettings()
            .subscribe(setting => {
                if (setting) {
                    this.setting = setting;
                    this.setDefaultFormats(setting);
                }
            });
    }

    async getTranslation() {
        this.translation = await this.get(`/public/get-translation`, (err: any) => {
            if (err) {
                this.notificationService.error(`${this.translate(["Error", "loading", "translation", "file"])}!`);
            }
        });
    }

    async logout() {
        await this.get(`/auth/log-out`);

        LocalStorageRepository.removeToken();
        LocalStorageRepository.removeUser();

        // TODO Temp fix. See what is using the user data from localStorage
        // if (LocalStorageRepository.getUser()) {
        //     LocalStorageRepository.removeUser();
        // }
        
        this.goTo('/log-in');
    }

    async delete(url: string, errCallback: Function = null, params?: any, headers?: any): Promise<any> {
        try {
            return await this.http
                .delete(`${url}`, {withCredentials: true, params, headers})
                .toPromise();
        } catch (err: any) {
            if (errCallback) {
                errCallback(err);
            } else {
                console.log(err);
                if (err && err.error.msg) {
                    this.notificationService.error(this.translate(err.error.msg));
                } else {
                    this.notificationService.error(this.translate(`Unexpected Error!`));
                }
            }
            return false;
        }
    }

    /**
     * 
     * @todo get rid of this by creating repositories for each model 
     * and interceptors and using them
     */
    async get(url: string, errCallback: Function = null, params?: any, headers?: any): Promise<any> {
        try {
            return await this.http
                .get(`${url}`, {withCredentials: true, params, headers})
                .toPromise();
        } catch (err: any) {
            if (errCallback) {
                errCallback(err);
            } else {
                if (err && err.error.msg) {
                    this.notificationService.error(this.translate(err.error.msg));
                } else {
                    this.notificationService.error(this.translate(`Unexpected Error!`));
                }
            }
            return false;
        }
    }

    /**
     * 
     * @todo get rid of this by creating repositories for each model 
     * and interceptors and using them
     */
    getObservable(url: string, errCallback: Function = null, params?: any, headers?: any): Observable<any> {
        return this.http.get(`${url}`, {
            withCredentials: true, params, headers
        });
    }

    /**
     * 
     * @todo get rid of this by creating repositories for each model 
     * and interceptors and using them
     */
    async post(url: string, data: any, errCallback: Function = null): Promise<any> {
        try {
            return await this.http
                .post(`${url}`, data, {withCredentials: true})
                .toPromise();
        } catch (err) {
            if (errCallback) {
                errCallback(err.error);
            } else {
                console.log(err);
                if (err && err.error.msg) {
                    this.notificationService.error(this.translate(err.error.msg));
                } else {
                    this.notificationService.error(this.translate(`Unexpected Error!`));
                }
            }
            return false;
        }
    }

    /**
     * 
     * @todo get rid of this by creating repositories for each model 
     * and interceptors and using them
     */
    postObservable(url: string, data: any, errCallback: Function = null): Observable<any> {
        return this.http
            .post(`${url}`, data, {withCredentials: true});
    }

    /****************************************************************
     * TRANSLATION
     ****************************************************************/

    setLanguage() {
        let language = localStorage.getItem("language");
        if (!language) {
            localStorage.setItem("language", "en");
            language = "en";
        }
        this.language = language;
    }

    /**
     * Translates a string or array of strings to the current language.
     * Supports parameter replacement using {n} placeholders.
     * 
     * @param sentence - The string or array of strings to translate
     * @param extra - Optional array of values to replace {n} placeholders in the translated string
     * 
     * @example
     * // Simple translation
     * translate('Hello') // => 'Bonjour'
     * 
     * // Array of words
     * translate(['Hello', 'World']) // => 'Bonjour Monde'
     * 
     * // With parameter replacement
     * translate('Hello {0}', ['John']) // => 'Bonjour John'
     * translate('From {0} to {1}', ['Monday', 'Friday']) // => 'De Lundi à Vendredi'
     * 
     * @returns The translated string with any parameters replaced
     */
    translate(sentence: string | string[], extra: any[] = null): string {
        let translated = ``;
        if (Array.isArray(sentence)) {
            for (let i = 0; i < sentence.length; i++) {
                let word = sentence[i];
                translated += `${this.getTranslatedWord(word)} `;
            }
        } else {
            translated = this.getTranslatedWord(sentence);
        }
        
        // Replace {n} placeholders with extra parameters
        if (extra) {
            extra.forEach((value, index) => {
                translated = translated.replace(`{${index}}`, value.toString());
            });
        }
        
        return translated;
    }

    getTranslatedWord(word: string): string {
        let translatedWord: string;
        if (this.translation[word] && this.translation[word][this.language]) {
            translatedWord = `${this.translation[word][this.language]}`;
        } else {
            translatedWord = `${word}`;
        }
        return translatedWord;
    }

    changeLanguage(language: string) {
        localStorage.setItem("language", language);
        this.language = language;
        this.appRef.tick();
    }

    /****************************************************************
     * ACCESS PERMISSIONS
     ****************************************************************/

    user(): any {
        try {
            return LocalStorageRepository.getLoginResponse();
        } catch (err) {
            return this.logout();
        }
    }

    isAccessAllowed(model: Array<string> | string, permission: string): boolean {
        try {
            let allowed = false;
            let user = LocalStorageRepository.getLoginResponse();
            if (user.role && this.allowedRoles.includes(user.role)) {
                allowed = true;
            }
            if (user.permissions) {
                if (Array.isArray(model)) {
                    for (let modelItem of model) {
                        if (user.permissions[modelItem][permission]) {
                            allowed = true;
                        }
                    }
                } else {
                    if (user.permissions[model][permission]) {
                        allowed = true;
                    }
                }
            }
            return allowed;
        } catch (err) {
            this.notificationService.error(this.translate(`Unexpected Error!`));
            return false;
        }
    }

    /****************************************************************
     * FORMAT CONFIGS
     ****************************************************************/
    /**
     * Set date/time/currency formats
     */
    setDefaultFormats(setting: any) {
        SettingLocalStorageRepository.setDateFormat(setting.dateFormat ? setting.dateFormat : DEFAULT_DATE_FORMAT);
        SettingLocalStorageRepository.setTimeFormat(setting.timeFormat ? setting.timeFormat : DEFAULT_TIME_FORMAT);
        SettingLocalStorageRepository.setCurrencyFormat(setting.currencyFormat ? setting.currencyFormat : DEFAULT_CURRENCY_FORMAT);
        SettingLocalStorageRepository.setCurrency(setting.currency);
    }

    // HELPERS

    goTo(routeName?: string, queryParams?: any) {
        if (routeName === "/") {
            this.router.navigate([`/`]);
        } else if (!routeName && !!queryParams) {
            this.router.navigate([], {
                queryParams: queryParams
            });
        } else if (!!queryParams) {
            this.router.navigate([`/${routeName}`], {
                queryParams: queryParams
            });
        } else {
            this.router.navigate([routeName]);
        }
    }

    dismissModal(name: string) {
        this.modals[name] = false;
        if (this.modalChanges$[name])
            this.modalChanges$[name].next(this.modals[name]);
    }

    toggleModal(name: string) {
        this.modals[name] = !this.modals[name];
        if (this.modalChanges$[name])
            this.modalChanges$[name].next(this.modals[name]);
    }

    isArray(data: any): boolean {
        return Array.isArray(data);
    }

    listenToModalChanges(modalKey: string) {
        if (!this.modalChanges$[modalKey])
            this.modalChanges$[modalKey] = new ReplaySubject<boolean>();
        return this.modalChanges$[modalKey].asObservable();
    }
}
