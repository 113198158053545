import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    constructor(
        private common: CommonService,
        private notificationService: NotificationService
    ) {}

    handleError(error: HttpErrorResponse): void {
        if (error.error?.msg) {
            const fullErrorMsg = error.error.msg;
            const lastErrorIndex = fullErrorMsg.lastIndexOf('Error: ');
            const actualErrorMessage =
                lastErrorIndex !== -1
                    ? fullErrorMsg.substring(lastErrorIndex + 7)
                    : fullErrorMsg;

            this.notificationService.error(
                this.common.translate(actualErrorMessage),
                this.common.translate('Ok')
            );
        } else {
            this.notificationService.error(
                this.common.translate('Unexpected Error!')
            );
        }
    }
}
